@import './one-light.less';

html {
  overflow-x: hidden
}

.bold {
  font-weight: bold;
}

.command-popper {
  margin-bottom: 20px !important
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.CodeMirror {
  height: 100%;
  font-family: "Roboto Mono", Helvetica, Arial, sans-serif !important;
  background: inherit !important;
  color: inherit !important;
}

.CodeMirror-search-field {
  font-family: inherit !important;
}

.CodeMirror-gutters, .CodeMirror-gutters {
  background: #fff;
  color: inherit !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right-width: thin !important;
}

.cm-s-material.CodeMirror .CodeMirror-gutters {
  background: #2c2c2c !important;
  border-right-color: rgba(255, 255, 255, 0.12) !important;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: inherit;
}

@keyframes enchants-animation {
  from { background-position-y: 128px }
  to { background-position-y: 0px }
}
