.cm-s-one-light .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}
.cm-s-one-light .cm-keyword {
  color: hsl(301, 63%, 40%);
}
.cm-s-one-light .cm-operator {
  color: hsl(301, 63%, 40%);
}
.cm-s-one-light .cm-variable-2 {
  color: hsl(230, 8%, 24%);
}
.cm-s-one-light .cm-variable-3,
.cm-s-one-light .cm-type {
  color: hsl(198, 99%, 37%);
}
.cm-s-one-light .cm-builtin {
  color: hsl(5, 74%, 59%);
}
.cm-s-one-light .cm-atom {
  color: hsl(35, 99%, 36%);
}
.cm-s-one-light .cm-number {
  color: hsl(221, 87%, 60%);
}
.cm-s-one-light .cm-def {
  color: hsl(221, 87%, 60%);
}
.cm-s-one-light .cm-string {
  color: hsl(119, 34%, 47%);
}
.cm-s-one-light .cm-string-2 {
  color: hsl(119, 34%, 47%);
}
.cm-s-one-light .cm-comment {
  color: hsl(230, 4%, 64%);
  font-style: italic;
}
.cm-s-one-light .cm-variable {
  color: hsl(5, 74%, 59%);
}
.cm-s-one-light .cm-tag {
  color: hsl(5, 74%, 59%);
}
.cm-s-one-light .cm-meta {
  color: meta;
}
.cm-s-one-light .cm-attribute {
  color: hsl(35, 99%, 36%);
}
.cm-s-one-light .cm-property {
  color: hsl(230, 8%, 24%);
}
.cm-s-one-light .cm-qualifier {
  color: hsl(230, 8%, 24%);
}
.cm-s-one-light .cm-variable-3,
.cm-s-one-light .cm-type {
  color: hsl(198, 99%, 37%);
}
.cm-s-one-light .cm-error {
  color: #ffffff;
  background-color: hsl(0, 100%, 54%);
}
.cm-s-one-light .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

html {
  overflow-x: hidden
}

.bold {
  font-weight: bold;
}

.command-popper {
  margin-bottom: 20px !important
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.CodeMirror {
  height: 100%;
  font-family: "Roboto Mono", Helvetica, Arial, sans-serif !important;
  background: inherit !important;
  color: inherit !important;
}

.CodeMirror-search-field {
  font-family: inherit !important;
}

.CodeMirror-gutters, .CodeMirror-gutters {
  background: #fff;
  color: inherit !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right-width: thin !important;
}

.cm-s-material.CodeMirror .CodeMirror-gutters {
  background: #2c2c2c !important;
  border-right-color: rgba(255, 255, 255, 0.12) !important;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: inherit;
}

@keyframes enchants-animation {
  from { background-position-y: 128px }
  to { background-position-y: 0px }
}

/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-cyrillic-ext-300-normal.d56cf99d.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-cyrillic-300-normal.86efcb4e.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-greek-ext-300-normal.419d96e4.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-greek-300-normal.1b04eab5.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-vietnamese-300-normal.3efdf108.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-latin-ext-300-normal.ee9d303a.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('roboto-latin-300-normal.dc97820c.woff2') format('woff2'), url('roboto-all-300-normal.de77deec.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-cyrillic-ext-400-normal.9bf2bae3.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-cyrillic-400-normal.1aa20856.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-greek-ext-400-normal.301c147a.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-greek-400-normal.ef0ccbee.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-vietnamese-400-normal.0acf03d4.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-latin-ext-400-normal.6f6851d7.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-latin-400-normal.c737a2de.woff2') format('woff2'), url('roboto-all-400-normal.2ec5e01f.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-cyrillic-ext-500-normal.1670d415.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-cyrillic-500-normal.0715a8cf.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-greek-ext-500-normal.a0424f19.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-greek-500-normal.463a6012.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-vietnamese-500-normal.e85e3ab5.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-latin-ext-500-normal.619cc9f9.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('roboto-latin-500-normal.4bcf99b5.woff2') format('woff2'), url('roboto-all-500-normal.c42777c3.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-cyrillic-ext-700-normal.306096bd.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-cyrillic-700-normal.de16b58f.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-greek-ext-700-normal.b04bcf3f.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-greek-700-normal.28c1d1c3.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-vietnamese-700-normal.b0f8e6b3.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-latin-ext-700-normal.4b4a09c6.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-latin-700-normal.86adbc06.woff2') format('woff2'), url('roboto-all-700-normal.9c467637.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-mono-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-cyrillic-ext-400-normal.dcb520ee.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-mono-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-cyrillic-400-normal.638e826e.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-mono-greek-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-greek-400-normal.dd843e41.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-mono-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-vietnamese-400-normal.306e7635.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-mono-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-latin-ext-400-normal.d9409874.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-mono-latin-400-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-latin-400-normal.cf1eee5f.woff2') format('woff2'), url('roboto-mono-all-400-normal.364ec368.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-mono-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-cyrillic-ext-700-normal.5c7aabac.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-mono-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-cyrillic-700-normal.5e6410cf.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-mono-greek-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-greek-700-normal.b101f80c.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-mono-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-vietnamese-700-normal.edb1435b.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-mono-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-latin-ext-700-normal.c497b002.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-mono-latin-700-normal*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-latin-700-normal.447ac127.woff2') format('woff2'), url('roboto-mono-all-700-normal.393c796d.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-mono-cyrillic-ext-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-cyrillic-ext-400-italic.db052448.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-mono-cyrillic-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-cyrillic-400-italic.e399ed93.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-mono-greek-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-greek-400-italic.6f7e0b2f.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-mono-vietnamese-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-vietnamese-400-italic.d8e0a32a.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-mono-latin-ext-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-latin-ext-400-italic.aaa9a959.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-mono-latin-400-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('roboto-mono-latin-400-italic.ef82d48f.woff2') format('woff2'), url('roboto-mono-all-400-italic.31a14f53.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-mono-cyrillic-ext-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-cyrillic-ext-700-italic.3739551a.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-mono-cyrillic-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-cyrillic-700-italic.c8bfc23e.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-mono-greek-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-greek-700-italic.330f9eac.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-mono-vietnamese-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-vietnamese-700-italic.c8976172.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-mono-latin-ext-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-latin-ext-700-italic.0e7ebaa2.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-mono-latin-700-italic*/
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('roboto-mono-latin-700-italic.f0276357.woff2') format('woff2'), url('roboto-mono-all-700-italic.261c0f7c.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumbers {}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker { color: black; }
.CodeMirror-guttermarker-subtle { color: #999; }

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection, 
.cm-fat-cursor .CodeMirror-line > span > span::selection { background: transparent; }
.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection { background: transparent; }
.cm-fat-cursor { caret-color: transparent; }
@-moz-keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}
@-webkit-keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}
@keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {}

.cm-tab { display: inline-block; text-decoration: inherit; }

.CodeMirror-rulers {
  position: absolute;
  left: 0; right: 0; top: -50px; bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0; bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {color: blue;}
.cm-s-default .cm-quote {color: #090;}
.cm-negative {color: #d44;}
.cm-positive {color: #292;}
.cm-header, .cm-strong {font-weight: bold;}
.cm-em {font-style: italic;}
.cm-link {text-decoration: underline;}
.cm-strikethrough {text-decoration: line-through;}

.cm-s-default .cm-keyword {color: #708;}
.cm-s-default .cm-atom {color: #219;}
.cm-s-default .cm-number {color: #164;}
.cm-s-default .cm-def {color: #00f;}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {}
.cm-s-default .cm-variable-2 {color: #05a;}
.cm-s-default .cm-variable-3, .cm-s-default .cm-type {color: #085;}
.cm-s-default .cm-comment {color: #a50;}
.cm-s-default .cm-string {color: #a11;}
.cm-s-default .cm-string-2 {color: #f50;}
.cm-s-default .cm-meta {color: #555;}
.cm-s-default .cm-qualifier {color: #555;}
.cm-s-default .cm-builtin {color: #30a;}
.cm-s-default .cm-bracket {color: #997;}
.cm-s-default .cm-tag {color: #170;}
.cm-s-default .cm-attribute {color: #00c;}
.cm-s-default .cm-hr {color: #999;}
.cm-s-default .cm-link {color: #00c;}

.cm-s-default .cm-error {color: #f00;}
.cm-invalidchar {color: #f00;}

.CodeMirror-composing { border-bottom: 2px solid; }

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {color: #0b0;}
div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #a22;}
.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
.CodeMirror-activeline-background {background: #e8f2ff;}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px; margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0; top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0; left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0; bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0; bottom: 0;
}

.CodeMirror-gutters {
  position: absolute; left: 0; top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0; bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection { background-color: transparent }
.CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {}

.CodeMirror-rtl pre { direction: rtl; }

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre { position: static; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected { background: #d9d9d9; }
.CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
.CodeMirror-crosshair { cursor: crosshair; }
.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */
.cm-force-border { padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after { content: ''; }

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext { background: none; }

/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/

.cm-s-material.CodeMirror {
  background-color: #263238;
  color: #EEFFFF;
}

.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: #546E7A;
  border: none;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: #546E7A;
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #FFCC00;
}
.cm-s-material.cm-fat-cursor .CodeMirror-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-material .cm-animate-fat-cursor {
  background-color: #5d6d5c80 !important;
}

.cm-s-material div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line>span::selection,
.cm-s-material .CodeMirror-line>span>span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line>span::-moz-selection,
.cm-s-material .CodeMirror-line>span>span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-material .cm-keyword {
  color: #C792EA;
}

.cm-s-material .cm-operator {
  color: #89DDFF;
}

.cm-s-material .cm-variable-2 {
  color: #EEFFFF;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #f07178;
}

.cm-s-material .cm-builtin {
  color: #FFCB6B;
}

.cm-s-material .cm-atom {
  color: #F78C6C;
}

.cm-s-material .cm-number {
  color: #FF5370;
}

.cm-s-material .cm-def {
  color: #82AAFF;
}

.cm-s-material .cm-string {
  color: #C3E88D;
}

.cm-s-material .cm-string-2 {
  color: #f07178;
}

.cm-s-material .cm-comment {
  color: #546E7A;
}

.cm-s-material .cm-variable {
  color: #f07178;
}

.cm-s-material .cm-tag {
  color: #FF5370;
}

.cm-s-material .cm-meta {
  color: #FFCB6B;
}

.cm-s-material .cm-attribute {
  color: #C792EA;
}

.cm-s-material .cm-property {
  color: #C792EA;
}

.cm-s-material .cm-qualifier {
  color: #DECB6B;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #DECB6B;
}


.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #FF5370;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

.CodeMirror-dialog {
  position: absolute;
  left: 0; right: 0;
  background: inherit;
  z-index: 15;
  padding: .1em .8em;
  overflow: hidden;
  color: inherit;
}

.CodeMirror-dialog-top {
  border-bottom: 1px solid #eee;
  top: 0;
}

.CodeMirror-dialog-bottom {
  border-top: 1px solid #eee;
  bottom: 0;
}

.CodeMirror-dialog input {
  border: none;
  outline: none;
  background: transparent;
  width: 20em;
  color: inherit;
  font-family: monospace;
}

.CodeMirror-dialog button {
  font-size: 70%;
}

/*# sourceMappingURL=index.c0da74f7.css.map */
